import {createContext, ReactNode, useContext} from "react"
import {TypedSupabaseClient} from "~/.shared/supabase/TypedSupabaseClient"

const SupabaseContext = createContext<{ supabase: TypedSupabaseClient } | null>(null);

export const SupabaseContextProvider = ({
  supabase,
  children
}: {
  supabase: TypedSupabaseClient,
  children?: ReactNode
}) => {
  return (
    <SupabaseContext.Provider
      value={{supabase}}
    >
      {children}
    </SupabaseContext.Provider>
  )
}
export const useSupabaseContext = () => {
  const context = useContext(SupabaseContext)
  if (!context) throw new Error("You can only use SupabaseContext within it's provider")
  return context
}